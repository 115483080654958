/*
This is where you write custom SASS
*/
@keyframes spinning_animation{
  0%{
    transform:scale(1) rotate(0deg);
  }
  50%{
    transform:scale(0.8) rotate(360deg);
  }
  100%{
    transform:scale(1) rotate(720deg);
  }
}

body[data-ajax-transitions="true"] #ajax-loading-screen[data-method="standard"] .loading-icon {
  display: block;
  opacity: 1;
  transition: .3s ease;
}

#ajax-loading-screen {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: .3s ease;
  top: 0;
  left: 0;
  display: none;
  z-index: 1000000000;
}

.loading-icon.spin, .portfolio-loading.spin, .pp_loaderIcon.spin, .default-loading-icon.spin {
  animation: 2s cubic-bezier(0.63,0.045,0.34,1) 0s normal none spinning_animation;
  -webkit-animation: 2s cubic-bezier(0.63,0.045,0.34,1) 0s normal none spinning_animation;
  animation-iteration-count: 1;
}

#ajax-loading-screen .loading-icon {
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}




body.mobile-nav-show #fullPageWrapper {
  transform: translateX(-400px);
}

#fullPageWrapper {
  transition: .3s ease-out;
  transform: translateX(0);
}

::placeholder {
}

.form-control {
}

.text-initial {
    text-transform: initial;
}

.backdrop-blur {
  background-color: rgba(233, 233, 233, 0.9);
}

@supports ((-webkit-backdrop-filter: blur(50px)) or (backdrop-filter: blur(50px))) {
  .backdrop-blur {
    background: rgba(245,245,245,0.75);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}

footer {
  span.footcolor {
    a {
      color: #fff !important;
      &:hover {
        color: #d9d9d9!important;
      }
    }
  }
}
//interior custom scss
body.interior {
    section[id*="interiorBanner"] {
        height: 530px;
        min-height: 400px;
        @media screen and (max-width: 1600px) {
            & {
                height: 40vw;
            }
        }
    }
    &.page-type-3 {
        .card {
            padding: 60px 55px;
            background: linear-gradient(#34302a 0%, #29241c 100%);
            p.h3 {
                text-transform: uppercase;
                color: #fff;
            }
            [id*="HeaderInfoDiv"] {
                font-size: 18px;
                font-weight: 300;
                color: #fff;
                line-height: 30px;
            
            }
            label {
                display: none;
            }
        }
    }
    &.page-type-26 {
        .page-content .container {
            max-width: 960px;
        }
    }
}


    /* Shape divider layer */
    .shape-divider-wrap {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 150px;
        z-index: 3;
        transform: translateZ(0);
      }
      .post-area.span_9 .shape-divider-wrap {
        overflow: hidden;
      }
      .shape-divider-wrap[data-position="top"] {
        top: -1px;
        bottom: auto;
      }
      .shape-divider-wrap[data-position="top"] {
        transform: rotate(180deg) 
      }
      .shape-divider-wrap[data-front="true"] {
        z-index: 50;
      }
      
      @media only screen and (min-width: 1000px) {
          .shape-divider-wrap[data-style="curve"] .shape-divider {
            filter: blur(0.5px);
            transform: scale(1.03);
          }
      }
      
      .shape-divider-wrap[data-style="waves_opacity"] svg path:first-child {
        opacity: 0.6;
      }
      @media only screen and (min-width: 1000px) {
        .shape-divider-wrap[data-style="clouds"] .shape-divider {
          min-width: 1700px;
        }
      }
      @media only screen and (max-width: 999px) {
        .shape-divider-wrap[data-style="clouds"] .shape-divider {
          min-width: 800px;
        }
      }
      @media only screen and (max-width: 690px) {
        .shape-divider-wrap[data-style="clouds"] .shape-divider {
          min-width: 690px;
        }
      }
      .shape-divider-wrap[data-style="fan"] svg {
        width: 102%;
        left: -1%;
      }
      .shape-divider-wrap[data-style="fan"] svg polygon:nth-child(2) {
        opacity: 0.15;
      }
      .shape-divider-wrap[data-style="fan"] svg rect {
        opacity: 0.3;
      }
      .shape-divider-wrap[data-style="mountains"] svg path:first-child {
        opacity: 0.1;
      }
      .shape-divider-wrap[data-style="mountains"] svg path:nth-child(2) {
        opacity: 0.12;
      }
      .shape-divider-wrap[data-style="mountains"] svg path:nth-child(3) {
        opacity: 0.18;
      }
      .shape-divider-wrap[data-style="mountains"] svg path:nth-child(4) {
        opacity: 0.33;
      }
      .shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(1), .shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(1) {
        opacity: 0.15;
      }
      .shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(2), .shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(2) {
        opacity: 0.3;
      }
      .shape-divider {
        width: 100%;
        left: 0;
        bottom: -1px;
        height: 100%;
        position: absolute;
      }
      .shape-divider-wrap.no-color .shape-divider {
        fill: #fff;
      }
      @media only screen and (max-width: 999px) {
        .shape-divider-wrap:not([data-using-percent-val="true"]) .shape-divider {
          height: 75%;
        }
        .shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"]) .shape-divider {
          height: 55%;
        }
      }
      @media only screen and (max-width: 690px) {
        .shape-divider-wrap:not([data-using-percent-val="true"]) .shape-divider {
          height: 33%;
        }
        .shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"]) .shape-divider {
          height: 33%;
        }
        
        .shape-divider-wrap[data-position=top] {
          top: 0px !important;
        }

      }
      
      .row-bg.using-bg-color {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          -webkit-background-size: cover;
      }

      .shape-divider-wrap[data-position=top] {
        top: -31px;
    }
    @media screen and (max-width: 999px) {
        .shape-divider-wrap[data-position=top] {
            top: -20px;
        }
    }

#to-top{
    display:block;
    position:fixed;
    text-align:center;
    line-height:12px;
    right:17px;
    bottom:-30px;
    color:#fff;
    cursor:pointer;
    border-radius: 100%;
    z-index:9994;
    height:29px;
    width:29px;
    background-color:rgba(0,0,0,0.25);
    background-repeat:no-repeat;
    background-position:center;
    transition:background-color 0.1s linear;
    -webkit-transition:background-color 0.1s linear;
    &:hover {
      background-color: $primary;
    }
    &:after {
      background-color: $primary !important;
    }
}

#to-top{
  transition:box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.1s linear;
  -webkit-transition:-webkit-box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.1s linear;
  background-color:rgba(0,0,0,0.25) 
}

#to-top:hover, 
#to-top.dark:hover{
  transition:box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.05s linear 0.25s;
  -webkit-transition:-webkit-box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.05s linear 0.25s;
  box-shadow:1px 2px 3px rgba(0,0,0,0.16);
  background-color:transparent!important 
}
#to-top:after, 
#to-top:before{
    display:block;
    content:' ';
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    background-color:#000;
    transform:scale(0);
    -webkit-transform:scale(0);
    transition:all 0.3s cubic-bezier(.55,0,.1,1);
    -webkit-transition:all 0.3s cubic-bezier(.55,0,.1,1);
    border-radius:100px;
    -webkit-border-radius:100px
}
#to-top:before{
  background-color:rgba(255,255,255,0.25);
  transform:scale(1);
  -webkit-transform:scale(1);
  transition:all 0.5s cubic-bezier(0.165,0.84,0.44,1);
  -webkit-transition:all 0.5s cubic-bezier(0.165,0.84,0.44,1);
  opacity:0;
  z-index:2
}

#to-top:hover:after{
  transform:scale(1);
  -webkit-transform:scale(1);
}
#to-top{
  overflow:hidden
}

#to-top i.fa-angle-up.top-icon, 
#to-top i.fa-angle-up{
  -webkit-transform:translate(0,0px);
  transform:translate(0,0px);
  transition:transform 0.2s ease;
  -webkit-transition:transform 0.2s ease;
}

#to-top:hover i.fa-angle-up.top-icon, 
#to-top:hover i.fa-angle-up, 
#to-top.hovered i.fa-angle-up.top-icon, 
#to-top.hovered i.fa-angle-up{
  -webkit-transform:translate(0,-29px);
  transform:translate(0,-29px)
}

#to-top:active:before{
  opacity:1
}

#to-top i{
  line-height:29px;
  width:29px;
  height:29px;
  font-size:14px;
  top:0;
  left:0;
  text-align:center;
  position:relative;
  z-index:10;
  background-color:transparent 
}
#to-top:hover, 
#to-top.dark:hover{
  background-color:#000
}
#to-top.dark{
  background-color:rgba(0,0,0,0.87)
}

      
/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }