section.content-img-section {
    padding-top: calc(100vw * 0.13);
    padding-bottom: calc(100vw* 0.13);

    @media screen and (max-width: 991px) {
        .shape-divider-wrap .shape-divider {
            height: 75%;
        }
    }
    
    @media screen and (max-width: 767px) {
        .shape-divider-wrap .shape-divider {
            height: 33%;
        }
    }
}