section.hero-section {
    min-height:400px;
    height: 800px;
    @include media-breakpoint-down(lg) {
        & {
            height: 600px !important;
        }
    }
    @include media-breakpoint-down(md) {
        & {
            height: 400px !important;
        }
    }
}