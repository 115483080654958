.font-size-17px {
font-size:17px !important;
}

.py-15px {
padding-top:15px !important;padding-bottom:15px !important;
}

.w-100 {
width:100% !important;
}

.pr-3 {
padding-right:3px !important;
}

.font-size-16px {
font-size:16px !important;
}

.mr-5px {
margin-right:5px !important;
}

.p-0 {
padding:0px !important;
}

.py-5px {
padding-top:5px !important;padding-bottom:5px !important;
}

.h-100 {
height:100% !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.m-0 {
margin:0px !important;
}

.font-weight-500 {
font-weight:500 !important;
}

.ml-10px {
margin-left:10px !important;
}

.ml-10px {
margin-left:10px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.font-size-25px {
font-size:25px !important;
}

.line-height-25px {
line-height:25px !important;
}

@media screen and (min-width: 992px) {.mt-lg-n140px {
margin-top:-140px !important;
}

}
 .py-20px {
padding-top:20px !important;padding-bottom:20px !important;
}

.h-100 {
height:100% !important;
}

.py-15px {
padding-top:15px !important;padding-bottom:15px !important;
}

.m-0 {
margin:0px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.px-10px {
padding-left:10px !important;padding-right:10px !important;
}

.p-30px {
padding:30px !important;
}

@media screen and (min-width: 1200px) {.p-xl-60px {
padding:60px !important;
}

}
 .h-100 {
height:100% !important;
}

.font-size-50px {
font-size:50px !important;
}

.mt-25px {
margin-top:25px !important;
}

.font-size-17px {
font-size:17px !important;
}

.p-30px {
padding:30px !important;
}

@media screen and (min-width: 1200px) {.p-xl-60px {
padding:60px !important;
}

}
 .h-100 {
height:100% !important;
}

.font-size-50px {
font-size:50px !important;
}

.h-100 {
height:100% !important;
}

.p-30px {
padding:30px !important;
}

@media screen and (min-width: 1200px) {.p-xl-60px {
padding:60px !important;
}

}
 .h-100 {
height:100% !important;
}

.font-size-50px {
font-size:50px !important;
}

@media screen and (min-width: 992px) {.py-lg-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

}
 .py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

.mb-45px {
margin-bottom:45px !important;
}

.px-15px {
padding-left:15px !important;padding-right:15px !important;
}

@media screen and (min-width: 768px) {.px-md-40px {
padding-left:40px !important;padding-right:40px !important;
}

}
 .font-weight-700 {
font-weight:700 !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.pb-30px {
padding-bottom:30px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.font-weight-700 {
font-weight:700 !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.mb-40px {
margin-bottom:40px !important;
}

.py-20px {
padding-top:20px !important;padding-bottom:20px !important;
}

.mb-40px {
margin-bottom:40px !important;
}

@media screen and (min-width: 992px) {.px-lg-50px {
padding-left:50px !important;padding-right:50px !important;
}

}
 .font-size-50px {
font-size:50px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.opacity-25 {
opacity:25 !important;
}

@media screen and (min-width: 992px) {.pb-lg-150px {
padding-bottom:150px !important;
}

}
 @media screen and (min-width: 992px) {.pt-lg-100px {
padding-top:100px !important;
}

}
 .pt-50px {
padding-top:50px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.px-lg-7 {
padding-left:7px !important;padding-right:7px !important;
}

}
 .m-0 {
margin:0px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.pb-30px {
padding-bottom:30px !important;
}

@media screen and (min-width: 992px) {.py-lg-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

}
 .py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.font-weight-700 {
font-weight:700 !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.font-size-18px {
font-size:18px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.line-height-30px {
line-height:30px !important;
}

.mt-30px {
margin-top:30px !important;
}

.w-100 {
width:100% !important;
}

.py-25px {
padding-top:25px !important;padding-bottom:25px !important;
}

.font-size-12px {
font-size:12px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.font-size-12px {
font-size:12px !important;
}

.font-size-10px {
font-size:10px !important;
}

.font-size-16px {
font-size:16px !important;
}

.mx-5px {
margin-left:5px !important;margin-right:5px !important;
}

@media screen and (min-width: 768px) {.mr-md-0 {
margin-right:0px !important;
}

}
 @media screen and (min-width: 768px) {.ml-md-10px {
margin-left:10px !important;
}

}
 .p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.h-100 {
height:100% !important;
}

.m-0 {
margin:0px !important;
}

.w-100 {
width:100% !important;
}

.h-100 {
height:100% !important;
}

.p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.px-4 {
padding-left:4px !important;padding-right:4px !important;
}

.pt-4 {
padding-top:4px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-1 {
margin-left:1px !important;margin-right:1px !important;
}

.pt-2 {
padding-top:2px !important;
}

@media screen and (min-width: 768px) {.pt-md-4 {
padding-top:4px !important;
}

}
 .pb-4 {
padding-bottom:4px !important;
}

.px-2 {
padding-left:2px !important;padding-right:2px !important;
}

.w-100 {
width:100% !important;
}

.p-25px {
padding:25px !important;
}

.font-size-16px {
font-size:16px !important;
}

.mx-5px {
margin-left:5px !important;margin-right:5px !important;
}

@media screen and (min-width: 768px) {.mr-md-0 {
margin-right:0px !important;
}

}
 @media screen and (min-width: 768px) {.ml-md-10px {
margin-left:10px !important;
}

}
 .opacity-75 {
opacity:75 !important;
}

